import { LinkModel } from '@model/link.model';
import { getUri } from './utils';

export const getFieldLink = (field_link): LinkModel => {
  if (!field_link) return null;

  return {
    label: field_link?.title,
    url: field_link?.url ? field_link?.url : getUri(field_link?.uri),
    isExternal: !/internal:/.test(field_link?.uri) && !/entity:/.test(field_link?.uri),
    rel: field_link?.options?.attributes?.rel,
    target: field_link?.options?.attributes?.target || '_self',
  };
};
