import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CarouselLastItemsParagraph from '@components/organisms/CarouselLastItemsParagraph/CarouselLastItemsParagraph';
import useOnScreen from '@utils/functions/useOnScreen';
import theme from '@mui/theme';
import CircularProgressCustom from '@atoms/CircularProgressCustom/CircularProgressCustom';

const ListingVideoViewsPlaylists = ({
  id,
  name,
  colorVariant,
  onSeeAllClick,
}: {
  id: number;
  name: string;
  colorVariant: string;
  onSeeAllClick: any;
}) => {
  const [playlistData, setPlaylistData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [loaded, setLoaded] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(ref);

  useEffect(() => {
    if (isOnScreen && !loaded) {
      const fetchPlaylist = async () => {
        setLoading(true);
        setError(null);

        try {
          const response = await fetch(`/api/video/get-playlist/${id}/`);
          if (!response.ok) {
            throw new Error('Errore nel recuperare i dati della playlist');
          }
          const data = await response.json();
          setPlaylistData(data?.data || []);
          setLoaded(true);
        } catch (error: any) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchPlaylist();
    }
  }, [isOnScreen, id, loaded]);

  return (
    <Box ref={ref} sx={{ '&:first-of-type': { marginTop: { xs: theme.spacing(-27.2), lg: theme.spacing(-40) } } }}>
      {loading ? (
        <CircularProgressCustom height={theme.spacing(480)} />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <CarouselLastItemsParagraph
            lastVideos={false}
            fieldParagraph={playlistData}
            id={id}
            name={name}
            itemsToDisplay={18}
            onSeeAllClick={onSeeAllClick}
          />
        </>
      )}
    </Box>
  );
};

export default ListingVideoViewsPlaylists;
