import { absoluteUrl, mapDescriptionFromParagraphs, mapVideosFromParagraphs } from '@utils/functions/utils';

export const mapPlaylistData = (playlistData: any[], lastVideos: boolean = false) => {
  return playlistData.map((item) => {
    const videoUrls = mapVideosFromParagraphs(item.field_paragraphs);
    const videoDescription = mapDescriptionFromParagraphs(item.field_paragraphs);

    return {
      title: item.title,
      cta: {
        url: item.field_link?.uri ?? item.path.alias,
        target: item.field_link?.uri ? '_blank' : '_self',
        isExternal: !!item.field_link?.uri,
      },
      tags: item?.field_tags?.map((tag: any) => tag.name),
      imageAlt: item.field_media?.field_media_image?.resourceIdObjMeta?.alt || '',
      imageUrl: absoluteUrl(item.field_media?.field_media_image?.uri?.url) || '',
      date: item.field_date ? new Date(item.field_date).toLocaleDateString() : '',
      variant: lastVideos ? 'last-items-card' : 'explore-videos-card',
      path: item.path?.alias,
      videoUrl: videoUrls.length > 0 ? videoUrls[0] : '',
      abstract: videoDescription || '',
    };
  });
};
