import { useCallback, useEffect, useState } from 'react';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';

export const useCarousel = (options: EmblaOptionsType = {}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: false,
      inViewThreshold: 0.6,
      axis: 'x',
      speed: 5,
      direction: 'ltr',
      align: 'start',
      skipSnaps: true,
      containScroll: 'keepSnaps',
      ...options,
    },
    [WheelGesturesPlugin()]
  );

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const selectSlideHandler = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  const { isLarger: isViewportLargerThanLg } = useBreakpoint('lg');

  useEffect(() => {
    if (!emblaApi) return;

    const scrollSnapList = emblaApi.scrollSnapList();
    emblaApi.scrollTo(0);
    setScrollSnaps(scrollSnapList);
  }, [emblaApi, isViewportLargerThanLg]);

  useEffect(() => {
    if (!emblaApi) return;

    selectSlideHandler();
    emblaApi.on('select', selectSlideHandler);

    return () => {
      emblaApi.off('select', selectSlideHandler);
    };
  }, [emblaApi]);

  return {
    selectedIndex,
    scrollSnaps,
    emblaApi,
    emblaRef,
  };
};
