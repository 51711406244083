import { useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinkChildren from '@atoms/LinkChildren/LinkChildren';
import CustomCard from '@atoms/CustomCard/CustomCard';
import theme from '@mui/theme';
import PaginationWrapper from '@molecules/PaginationWrapper/PaginationWrapper';
import { useMediaQuery } from '@mui/material';
import Row from '@atoms/CustomGrid/Row';
import CircularProgressCustom from '@atoms/CircularProgressCustom/CircularProgressCustom';
import { mapPlaylistData } from './ListingVideoViews.mapper';
import { useTranslation } from 'next-i18next';

const ITEMS_PER_PAGE = 30;

const ListingVideoViewsPlaylist = ({ colorVariant, plId, name = '' }) => {
  const [playlistData, setPlaylistData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { t } = useTranslation();

  const fetchPlaylist = async (page = 1) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`/api/video/get-playlist/${plId}?page=${page}&items=${ITEMS_PER_PAGE}`);
      if (!response.ok) {
        throw new Error('Errore nel recuperare i dati della playlist');
      }
      const data = await response.json();
      setPlaylistData(data?.data || []);
      setTotalItems(data.pagination.totalItems);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!plId) return;
    fetchPlaylist(currentPage);
  }, [plId, currentPage]);

  const memoizedPlaylist = useMemo(() => {
    return mapPlaylistData(playlistData);
  }, [playlistData]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const itemsPerRow = isMobile ? 2 : 6;
  const cardHeight = isMobile ? 215 : 305;
  const rowCount = Math.ceil(memoizedPlaylist.length / itemsPerRow);
  const totalHeight = rowCount * cardHeight || cardHeight;

  const textColor = colorVariant === 'light' ? 'grey.900' : 'common.white';

  return (
    <Box
      sx={{
        color: textColor,
        marginTop: 0,
      }}
    >
      <Typography variant={isMobile ? 'h6' : 'h3'} component={'h3'} fontWeight="500" color={textColor}>
        {name}
      </Typography>

      <Row>
        {loading ? (
          <CircularProgressCustom height={totalHeight} />
        ) : memoizedPlaylist.length ? (
          memoizedPlaylist.map((item, index) => (
            <Grid item xs={6} md={4} lg={2} key={index} mt={{ xs: theme.spacing(8), lg: theme.spacing(24) }}>
              <LinkChildren isExternal={item.cta.isExternal} url={item.cta.url} target={item.cta.target}>
                <CustomCard
                  imageUrl={item.imageUrl}
                  createdAtDate={item.date}
                  tags={item.tags}
                  imageAlt={item.imageAlt}
                  label={item.title}
                  variant={'explore-videos-card'}
                  path={item.path}
                  videoUrl={item.videoUrl}
                  abstract={item.abstract}
                />
              </LinkChildren>
            </Grid>
          ))
        ) : (
          <Box display={'flex'} width={'100%'} justifyContent={'center'}>
            <Typography mt={theme.spacing(40)} variant={'bodyM'} color={textColor}>
              {t('explorerVideo.noVideos')}
            </Typography>
          </Box>
        )}
      </Row>

      {totalItems > ITEMS_PER_PAGE && (
        <PaginationWrapper
          page={currentPage}
          colorVariant={colorVariant}
          itemsPerPage={ITEMS_PER_PAGE}
          totalCount={totalItems}
          onPageChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default ListingVideoViewsPlaylist;
