import { useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { DynamicBlockProps } from './DynamicBlock.props';
import CarouselLastVideosParagraph from '@components/organisms/CarouselLastItemsParagraph/CarouselLastItemsParagraph';
import ListingVideoParagraph from '@components/organisms/ListingVideoParagraph/ListingVideoParagraph';
const IntroParagraph = dynamic(() => import('@organisms/IntroParagraph/IntroParagraph'));
const FullWidthSliderParagraph = dynamic(() => import('@organisms/FullWidthSliderParagraph/FullWidthSliderParagraph'));
const TabsParagraph = dynamic(() => import('@organisms/TabsParagraph/TabsParagraph'));
const HeroParagraph = dynamic(() => import('@organisms/HeroParagraph/HeroParagraph'));
const WallParagraph = dynamic(() => import('@organisms/WallParagraph/WallParagraph'));
const HighlightsParagraph = dynamic(() => import('@organisms/HighlightsParagraph/HighlightsParagraph'));
const CarouselParagraph = dynamic(() => import('@organisms/CarouselParagraph/CarouselParagraph'));
const CarouselWithTextParagraph = dynamic(
  () => import('@organisms/CarouselWithTextParagraph/CarouselWithTextParagraph')
);
const LaunchesParagraph = dynamic(() => import('@organisms/LaunchesParagraph/LaunchesParagraph'));
const HubDiscoveryParagraph = dynamic(() => import('@organisms/HubDiscoveryParagraph/HubDiscoveryParagraph'));
const FullscreenIntro = dynamic(() => import('@organisms/FullscreenIntro/FullscreenIntro'));
const GalleryParagraph = dynamic(() => import('@organisms/GalleryParagraph/GalleryParagraph'));
const FabParagraph = dynamic(() => import('@organisms/FabParagraph/FabParagraph'));
const CarouselIntroParagraph = dynamic(() => import('@organisms/CarouselIntroParagraph/CarouselIntroParagraph'));
const CardWallParagraph = dynamic(() => import('@organisms/CardWallParagraph/CardWallParagraph'));
const QuoteParagraph = dynamic(() => import('@organisms/QuoteParagraph/QuoteParagraph'));
const EditorialSplitParagraph = dynamic(() => import('@organisms/EditorialSplitParagraph/EditorialSplitParagraph'));
const DocumentCollectionParagraph = dynamic(
  () => import('@organisms/DocumentCollectionParagraph/DocumentCollectionParagraph')
);
const CarouselTextFourCardsParagraph = dynamic(
  () => import('@organisms/CarouselTextFourCardsParagraph/CarouselTextFourCardsParagraph')
);
const FeaturedImageParagraph = dynamic(() => import('@organisms/FeaturedImageParagraph/FeaturedImageParagraph'));
const NewsletterParagraph = dynamic(() => import('@organisms/NewsletterParagraph/NewsletterParagraph'));
const AccordionsParagraph = dynamic(() => import('@organisms/AccordionsParagraph/AccordionsParagraph'));
const RichTextParagraph = dynamic(() => import('@organisms/RichTextParagraph/RichTextParagraph'));
const GenericTabsParagraph = dynamic(() => import('@organisms/GenericTabsParagraph/GenericTabsParagraph'));
const PodcastSection = dynamic(() => import('@organisms/PodcastSection/PodcastSection'));
const TableParagraph = dynamic(() => import('@organisms/TableParagraph/TableParagraph'));
const ListingWallParagraph = dynamic(() => import('@organisms/ListingWallParagraph/ListingWallParagraph'));
const MediaWallParagraph = dynamic(() => import('@organisms/MediaWallParagraph/MediaWallParagraph'));
const ProjectsWallParagraph = dynamic(() => import('@organisms/ProjectsWallParagraph/ProjectsWallParagraph'));

const DynamicBlock = ({ type, fieldData, node }: DynamicBlockProps): JSX.Element => {
  const getExposedViewBlock = useCallback(() => {
    const carouselType = (
      +process.env.NEXT_PUBLIC_USE_MOCK_API
        ? fieldData.carouselType
        : fieldData.behavior_settings?.style_options?.exposed_view_styles?.css_class
    ) as
      | 'carousel-card'
      | 'carousel-poster'
      | 'carousel-poster-text'
      | 'carousel-small-cards'
      | 'project-wall'
      | 'listing-wall'
      | 'media-wall'
      | 'discovery-wall';

    switch (carouselType) {
      case 'carousel-card':
        return <CarouselParagraph node={node} fieldParagraph={fieldData} />;

      case 'carousel-poster':
        return <CarouselIntroParagraph node={node} fieldParagraph={fieldData} />;

      case 'carousel-poster-text':
        return <CarouselWithTextParagraph node={node} fieldParagraph={fieldData} />;

      case 'carousel-small-cards':
        return <CarouselTextFourCardsParagraph node={node} fieldParagraph={fieldData} />;

      case 'project-wall':
        return <ProjectsWallParagraph node={node} fieldParagraph={fieldData} />;

      case 'listing-wall':
        return <ListingWallParagraph node={node} fieldParagraph={fieldData} />;

      case 'media-wall':
      case 'discovery-wall':
        return <MediaWallParagraph node={node} fieldParagraph={fieldData} />;

      default:
        return <></>;
    }
  }, [node, fieldData]);

  return useMemo(() => {
    switch (type) {
      case 'paragraph--hero':
        return <HeroParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--fullwidth_slider':
        return <FullWidthSliderParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--card_wall':
        return <CardWallParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--videos':
        return <GalleryParagraph node={node} type="video" fieldParagraph={fieldData} />;

      case 'paragraph--gallery':
        return <GalleryParagraph node={node} type="image" fieldParagraph={fieldData} />;

      case 'paragraph--tabs':
        return <TabsParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--fab':
        return <FabParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--jumbo':
        return <FullscreenIntro node={node} fieldParagraph={fieldData} />;

      case 'paragraph--title_text_cta':
        return <IntroParagraph showCategory showBg fieldParagraph={fieldData} node={node} />;

      case 'paragraph--scrolling_wall':
        return <WallParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--highlights':
        return <HighlightsParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--exposed_view':
        return getExposedViewBlock();

      case 'paragraph--launches':
        return <LaunchesParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--live_text_flashtopics':
        return <HubDiscoveryParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--quote':
        return <QuoteParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--editorial_split':
        return <EditorialSplitParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--attachments':
        return <DocumentCollectionParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--featured_image_info':
        return <FeaturedImageParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--form_custom_newsletter':
        return <NewsletterParagraph fieldParagraph={fieldData} node={node} />;

      case 'paragraph--accordion':
        return <AccordionsParagraph node={node} fieldParagraph={fieldData} />;

      case 'paragraph--rich_text':
        return <RichTextParagraph fieldParagraph={fieldData} node={node} />;

      case 'paragraph--generic_tabs':
        return <GenericTabsParagraph fieldParagraph={fieldData} node={node} />;

      case 'paragraph--podcast':
        return <PodcastSection fieldParagraph={fieldData} node={node} />;

      case 'paragraph--table':
        return <TableParagraph fieldParagraph={fieldData} />;

      case 'paragraph--carousel_last_items':
        return <CarouselLastVideosParagraph fieldParagraph={fieldData} lastVideos={true} />;

      case 'paragraph--listing_video':
        return <ListingVideoParagraph fieldParagraph={fieldData} node={node} />;

      default:
        return <></>;
    }
  }, [type, getExposedViewBlock, fieldData, node]);
};

export default DynamicBlock;
