import styles from './SearchVideoInput.module.scss';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState, useEffect, useRef } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import useSearchVideo from '@utils/functions/useSearchVideo';

const highlightText = (text: string, query: string) => {
  if (!query) return text;
  const regex = new RegExp(`(${query})`, 'gi');
  return text.replace(regex, '<strong>$1</strong>');
};

const SearchVideoInput = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const router = useRouter();
  const [searchKey, setSearchKey] = useState('');
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);

  const { searchResponse, isFetching, page, setPage } = useSearchVideo({
    limit: 10,
    inputSearchKey: searchKey,
  });

  const suggestions = searchResponse?.items || [];

  const inputRef = useRef<HTMLDivElement>(null);

  const handleSearch = () => {
    if (searchKey.trim().length < 1) {
      return;
    }
    router.push(`/${router.locale}/video-search/?q=${encodeURIComponent(searchKey)}`);
    setIsSuggestionsOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearchKey(suggestion);
    setIsSuggestionsOpen(false);
    handleSearch();
  };

  useEffect(() => {
    if (searchKey.length > 0) {
      setIsSuggestionsOpen(true);
    } else {
      setIsSuggestionsOpen(false);
    }
  }, [searchKey]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsSuggestionsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  return (
    <FormControl ref={inputRef} variant="outlined" sx={{ margin: 'auto', width: '100%' }}>
      <InputLabel htmlFor="outlined-adornment-search">
        <Typography color={theme.palette.common.white} textAlign="left" variant="overline" component="p" fontSize={16}>
          {t('search.search')}
        </Typography>
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-search"
        type="text"
        value={searchKey}
        onChange={(e) => setSearchKey(e.target.value)}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label={t('search.search')} onClick={handleSearch} edge="end">
              <SearchIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </InputAdornment>
        }
        label={t('search.search')}
        onFocus={() => setIsSuggestionsOpen(true)}
      />

      {isSuggestionsOpen && !isFetching && suggestions.length > 0 && (
        <Box
          className={styles.suggestionsBox}
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <List>
            {suggestions.map((suggestion, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => handleSuggestionClick(suggestion.title)}>
                  <span dangerouslySetInnerHTML={{ __html: highlightText(suggestion.title, searchKey) }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </FormControl>
  );
};

export default SearchVideoInput;
