import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import { absoluteUrl, GLOBAL_QUERY_STALE_TIME, mapDescriptionFromParagraphs, mapVideosFromParagraphs } from './utils';
import { CTX_TAX_MAP } from '@utils/constants/constants';
import { useTextCorrection } from '@hooks/useTextCorrection';

export interface UseSearchParams {
  limit?: number;
  inputSearchKey: string;
  fieldContextFilter?: string;
  orderDirection?: 'asc' | 'desc';
  discoveryArgumentsFilter?: string | string[];
}

const useSearchVideo = (params: UseSearchParams) => {
  const { inputSearchKey, limit = 10, fieldContextFilter, orderDirection, discoveryArgumentsFilter } = params;

  const { locale } = useRouter();
  const [page, setPage] = useState(1);
  const offset = useMemo(() => (page - 1) * limit, [limit, page]);

  const {
    data: searchResponse,
    isFetching,
    isFetched,
    refetch,
  } = useQuery(
    [
      'search-videos',
      inputSearchKey,
      offset,
      limit,
      fieldContextFilter,
      orderDirection,
      discoveryArgumentsFilter,
      locale,
    ],
    async () => {
      if (!inputSearchKey?.length) {
        return null;
      }

      const params = new DrupalJsonApiParams();
      params.addPageOffset(offset);
      params.addPageLimit(limit);

      // Filtro per fulltext (ricerca per keyword)
      inputSearchKey && params.addFilter('fulltext', inputSearchKey);

      // Filtro per fieldContext
      params.addFilter('field_context', process.env.NEXT_PUBLIC_MULTISITE_CONTEXT_TAX);

      // Filtro per locale
      params.addFilter('language_with_fallback', locale);

      // Filtro per discovery arguments
      if (discoveryArgumentsFilter) {
        const formattedDiscoveryArgumentsFilter = Array.isArray(discoveryArgumentsFilter)
          ? discoveryArgumentsFilter
          : [discoveryArgumentsFilter];

        formattedDiscoveryArgumentsFilter.forEach((categoryId) => {
          params.addFilter('field_categories', categoryId, 'IN');
        });
      }

      // Ordinamento
      if (orderDirection) {
        params.addSort('created_node', orderDirection);
        params.addSort('created_media', orderDirection);
      }

      params.addInclude([
        'field_media.field_media_image',
        'field_media_document',
        'field_medias.field_media_video_file',
        'field_context',
        'field_taxonomy',
        'field_tags',
        'field_paragraphs',
        'field_paragraphs.field_medias',
        'field_medias.field_media_document',
        'field_medias.field_media_document.uri.url',
      ]);

      const { data: response } = await axios.post('/api/search/videos/', {
        params: {
          ...params.getQueryObject(),
          jsonapi_include: 1,
        },
        deserialize: false,
        locale,
      });

      return {
        items: response.data.map(itemResultMapper),
        count: response.meta.count,
      };
    },
    {
      keepPreviousData: true,
      staleTime: GLOBAL_QUERY_STALE_TIME,
    }
  );

  return {
    isFetching,
    isFetched,
    searchResponse,
    page,
    setPage,
  };
};

const itemResultMapper = (item) => {
  const videoUrls = mapVideosFromParagraphs(item.field_paragraphs);
  const videoDescription = mapDescriptionFromParagraphs(item.field_paragraphs);
  return {
    title: item.title,
    cta: {
      url: item.field_link?.uri ?? item.path.alias,
      target: item.field_link?.uri ? '_blank' : '_self',
      isExternal: !!item.field_link?.uri,
    },
    tags: Array.isArray(item?.field_tags) ? item.field_tags.map((tag: any) => tag.name) : [],
    imageAlt: item.field_media?.field_media_image?.resourceIdObjMeta?.alt || '',
    imageUrl: absoluteUrl(item.field_media?.field_media_image?.uri?.url) || '',
    date: item.field_date ? new Date(item.field_date).toLocaleDateString() : '',
    variant: 'explore-videos-card',
    path: item.path?.alias,
    videoUrl: videoUrls.length > 0 ? videoUrls[0] : '',
    abstract: videoDescription || '',
  };
};

export default useSearchVideo;
