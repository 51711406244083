import theme from '@mui/theme';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

import { RoundedIconButtonProps } from '@atoms/RoundedIconButton/RoundedIconButton.props';

import { useColors } from '@hooks/useColors';

const RoundedIconButton = ({ iconName, label, colorVariant, ...otherProps }: RoundedIconButtonProps) => {
  const { textColor } = useColors(colorVariant);

  return (
    <Box
      display="inline-flex"
      minWidth={{ xs: 0, lg: 60 }}
      gap={12}
      alignItems="center"
      flexDirection={{ xs: 'row-reverse', lg: 'column' }}
    >
      {iconName && (
        <Button
          {...otherProps}
          disableRipple
          variant="large"
          color="secondary"
          sx={{
            border: `${theme.spacing(2)} solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            borderRadius: '50%',
            minWidth: 'unset',
            height: { xs: theme.spacing(32), lg: theme.spacing(48) },
            width: { xs: theme.spacing(32), lg: theme.spacing(48) },
            padding: 0,

            '> .MuiIcon-root': {
              fontSize: { xs: theme.spacing(12), lg: theme.spacing(24) },
            },

            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme.palette.primary['900'],
              color: theme.palette.primary['900'],
            },

            '&:disabled': {
              borderColor: theme.palette.grey['200'],

              '> .ButtonIcon': {
                color: theme.palette.grey['400'],
              },
            },
          }}
        >
          <Icon>{iconName}</Icon>
        </Button>
      )}
      {label && (
        <Typography color={textColor} variant="overlineMono">
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default RoundedIconButton;
