import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  className?: string;
}

const FadeIn = ({ children, className }: Props) => {
  return (
    <motion.div
      className={className}
      initial={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true, margin: '0px 0px -200px 0px' }}
      whileInView={{ opacity: 1, y: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default FadeIn;
