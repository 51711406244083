import styles from './ListingVideoParagraph.module.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { useBreakpoint } from '@hooks/useBreakpoint';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton, Tab, Tabs, Menu, MenuItem } from '@mui/material';
import ListingVideoViewsPlaylist from '@components/molecules/ListingVideoViews/ListingVideoViewsPlaylist';
import ListingVideoViewsPlaylists from '@components/molecules/ListingVideoViews/ListingVideoViewsPlaylists';
import { getCookie } from '@utils/functions/saveNidInCookie';
import ListingVideoViewsLastVideos from '@components/molecules/ListingVideoViews/ListingVideoViewsLastVideos';
import BackgroundTransitionBox from '@atoms/BackgroundTransitionBox/BackgroundTransitionBox';
import { transformTitleId } from '@utils/functions/utils';
import SearchVideoInput from '@atoms/SearchVideoInput/SearchVideoInput';

const Box = dynamic(() => import('@mui/material/Box'), { ssr: false });
const Grid = dynamic(() => import('@mui/material/Grid'), { ssr: false });
const FadeIn = dynamic(() => import('@atoms/FadeIn'), { ssr: false });
const Row = dynamic(() => import('@atoms/CustomGrid/Row'), { ssr: false });
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'), { ssr: false });
const ExpandMore = dynamic(() => import('@mui/icons-material/ExpandMore'), { ssr: false });

// const TAB_HEIGHT = 730;
const TAB_HEIGHT = 'auto';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ListingVideoParagraph = ({ node, fieldParagraph }: FieldParagraphProps) => {
  const { t } = useTranslation();

  const iconButtonRef = useRef(null);
  const [playlists, setPlaylists] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [playlistIdToShow, setPlaylistIdToShow] = useState(null);
  const [playlistNameToShow, setPlaylistNameToShow] = useState(null);

  const data = useMemo(() => {
    const colorVariant = fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class || 'dark';
    const newData: any = {
      //TODO:cambiare tipo in TabContentModel
      title: fieldParagraph.field_title,
      overline: fieldParagraph.field_overline,
      verticalLinesDirection: fieldParagraph.behavior_settings?.style_options?.vertical_lines?.css_class || 'none',
      colorVariant,
      // items: fieldParagraph.field_items.map((item) => {
      //   const props = getHomepagePanelProps(item, t);

      //   return {
      //     title: item.field_overline,
      //     content: <HomepageTabPanel {...props} colorVariant={colorVariant} />,
      //   };
      // }),
      anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
    };

    return newData;
  }, [
    fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class,
    fieldParagraph.behavior_settings?.style_options?.toc?.label,
    fieldParagraph.behavior_settings?.style_options?.vertical_lines?.css_class,
    fieldParagraph.field_items,
    fieldParagraph.field_overline,
    fieldParagraph.field_title,
    t,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/video/get-taxonomy-term/video_playlist');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setPlaylists(result);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fieldParagraph]);

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [tabSelected, setTabSelected] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
    if (newValue !== 2) setPlaylistNameToShow('Playlist');
  };

  const handleMenuPlaylistOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuPlaylistClose = (id, name) => {
    if (id === 'backdropClick' || id === 0) {
      setAnchorEl(null);
    } else if (name !== 'Playlist') {
      setPlaylistNameToShow('Playlist');
      setTabSelected(2);
      setPlaylistIdToShow(id);
      setPlaylistNameToShow(name);
      setAnchorEl(null);
    }
  };

  const boxRef = useRef<HTMLDivElement>();

  const { isLarger } = useBreakpoint('lg');

  const verticalPadding = useMemo(() => {
    return isLarger ? 64 : 40;
  }, [isLarger]);

  if (!data) return null;

  const handleSeeAllClick = (plId, plName) => {
    handleMenuPlaylistClose(plId, plName);
  };

  const nids = getCookie('discovery_video_nids');

  return (
    <BackgroundTransitionBox
      anchor-data={transformTitleId(data.anchorData || '')}
      pt={verticalPadding}
      pb={60}
      position="relative"
      ref={boxRef}
      linesDirection={data.direction || 'none'}
      colorVariant={data.colorVariant || 'dark'}
      transition={data.backgroundTransition || 'dark-to-light'}
      className={styles.listingVideoParagraph}
    >
      <FadeIn>
        <GridContainer id="ListingVideoParagraph">
          <Box position="relative">
            <Row>
              <Grid item lg={8} xs={12}>
                <Typography
                  color={theme.palette.common.white}
                  textAlign="left"
                  mb={{ lg: isMobile ? 40 : 24 }}
                  variant="h1"
                  component="h2"
                >
                  {t('explorerVideo.explore')}
                </Typography>
              </Grid>
              <Grid item lg={4} xs={12} display={'flex'} marginTop={isMobile ? 24 : 0} marginBottom={isMobile ? 40 : 0}>
                <SearchVideoInput />
              </Grid>
            </Row>
          </Box>
          <Box
            sx={{
              position: 'relative',
              height: TAB_HEIGHT,
              paddingTop: isMobile ? 0 : 40,
            }}
          >
            <Row>
              <Grid item xs={12}>
                <Box className={styles.contNavTabs} sx={{ width: '100%', marginBottom: isMobile ? 24 : 40 }}>
                  <Tabs
                    variant="scrollable"
                    value={tabSelected}
                    onChange={handleChangeTab}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: theme.palette.primary.main,
                        height: theme.spacing(3),
                      },
                    }}
                    sx={{
                      position: 'relative',
                      zIndex: 1,
                      '& .MuiTabs-flexContainer': {
                        borderBottom: isMobile ? 0 : `3px solid #4F4F4F`,
                      },
                      '& .MuiTab-root': {
                        textTransform: 'none',
                        paddingBottom: isMobile ? 6 : 16,
                      },
                      '& .Mui-selected p': {
                        fontWeight: 700,
                      },
                    }}
                  >
                    <Tab
                      label={
                        <Typography color={theme.palette.common.white} variant="subtitle" component="p">
                          {t('explorerVideo.allPlaylists')}
                        </Typography>
                      }
                    />
                    {nids.length && (
                      <Tab
                        label={
                          <Typography color={theme.palette.common.white} variant="subtitle" component="p">
                            {t('explorerVideo.latestViewed')}
                          </Typography>
                        }
                      />
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <Box display={'flex'} pb={{ xs: 0, lg: theme.spacing(16) }}>
                        <IconButton size="small" onClick={handleMenuPlaylistOpen} ref={iconButtonRef}>
                          <Typography
                            mr={theme.spacing(8)}
                            color={theme.palette.common.white}
                            variant="subtitle"
                            component="p"
                          >
                            {playlistNameToShow || 'Playlist'}{' '}
                          </Typography>
                          <ExpandMore sx={{ color: theme.palette.primary.main }} />
                        </IconButton>
                      </Box>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => handleMenuPlaylistClose(0, '')}
                        anchorOrigin={{
                          vertical: 45,
                          horizontal: 0,
                        }}
                        sx={{
                          '& .MuiPaper-root': {
                            borderRadius: `0 0 ${theme.spacing(24)} ${theme.spacing(24)}`,
                            maxHeight: theme.spacing(390),
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                              width: theme.spacing(6),
                              borderRadius: theme.spacing(100),
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.secondary['300'],
                            },
                            '&::-webkit-scrollbar-track': {
                              backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            },
                          },
                        }}
                      >
                        {playlists?.map((playlist, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => handleMenuPlaylistClose(playlist?.id, playlist?.name)}
                            sx={{
                              padding: `${theme.spacing(12)} ${theme.spacing(16)} !important`,
                              paddingTop: index === 0 && `${theme.spacing(20)} !important`,
                            }}
                          >
                            {playlist?.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Tabs>
                </Box>
                <TabPanelCustom value={tabSelected} index={0}>
                  <Box marginTop={theme.spacing(-40)} paddingTop={0} marginBottom={0}>
                    {playlists?.map(({ id, name }, index) => (
                      <>
                        <ListingVideoViewsPlaylists
                          key={id}
                          id={id}
                          name={name}
                          colorVariant={'dark'}
                          onSeeAllClick={handleSeeAllClick}
                        />
                      </>
                    ))}
                  </Box>
                </TabPanelCustom>
                <TabPanelCustom value={tabSelected} index={1}>
                  <ListingVideoViewsLastVideos name={t('explorerVideo.latestViewed')} colorVariant={'dark'} />
                </TabPanelCustom>
                <TabPanelCustom value={tabSelected} index={2}>
                  {playlistIdToShow && (
                    <ListingVideoViewsPlaylist
                      colorVariant={'dark'}
                      plId={playlistIdToShow}
                      name={playlistNameToShow}
                    />
                  )}
                </TabPanelCustom>
              </Grid>
            </Row>
          </Box>
        </GridContainer>
      </FadeIn>
    </BackgroundTransitionBox>
  );
};

export default ListingVideoParagraph;

function TabPanelCustom(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}
