import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { PaginationWrapperProps } from '@molecules/PaginationWrapper/PaginationWrapper.props';

const PaginationWrapper = (props: PaginationWrapperProps) => {
  const { page, setBoxRef, colorVariant, itemsPerPage, totalCount, onPageChange } = props;

  return (
    <Box
      ref={setBoxRef}
      marginTop={{ xs: 32, lg: 80 }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      py={20}
    >
      <Box
        bgcolor={colorVariant === 'light' ? 'alphaWhite.50' : 'alphaBlack.50'}
        sx={{ backdropFilter: 'blur(8px)' }}
        p=".7rem 1.4rem"
        borderRadius="1.4rem"
      >
        <Pagination
          sx={{
            '&.MuiPagination-root': { margin: '0' },
          }}
          count={Math.ceil(totalCount / itemsPerPage)}
          defaultPage={1}
          page={page}
          variant={colorVariant === 'light' ? 'onLight' : 'onDark'}
          onChange={(ev, page) => onPageChange(page)}
        />
      </Box>
    </Box>
  );
};

export default PaginationWrapper;
