import styles from './CarouselLastItemsParagraph.module.scss';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import GridContainer from '@atoms/GridContainer/GridContainer';
import Box from '@mui/material/Box';
import Row from '@atoms/CustomGrid/Row';
import { CarouselModel } from '@model/carousel.model';
import { useColors } from '@hooks/useColors';
import { useCarousel } from '@hooks/useCarousel';
import { getFieldLink } from '@utils/functions/getFieldLink';
import { absoluteUrl } from '@utils/functions/utils';
import FadeIn from '@atoms/FadeIn';
import { Button, Grid, useMediaQuery } from '@mui/material';
import LinkChildren from '@atoms/LinkChildren/LinkChildren';
import CustomCard from '@atoms/CustomCard/CustomCard';
import RoundedIconButton from '@atoms/RoundedIconButton/RoundedIconButton';
import LoadingSpinner from '@atoms/LoadingSpinner/LoadingSpinner';
import theme from '@mui/theme';
import { CarouselLastItemsParagraphModel } from './CarouselLastItemsParagraph.model';
import { useRouter } from 'next/router';
import EastIcon from '@mui/icons-material/East';
import CircularProgressCustom from '@atoms/CircularProgressCustom/CircularProgressCustom';
import { mapPlaylistData } from '@components/molecules/ListingVideoViews/ListingVideoViews.mapper';
import { useTranslation } from 'next-i18next';

const CarouselLastItemsParagraph = ({
  fieldParagraph,
  lastVideos,
  id,
  name,
  itemsToDisplay = 10,
  onSeeAllClick,
  index,
}: CarouselLastItemsParagraphModel) => {
  const { locale } = useRouter();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [isHovered, setIsHovered] = useState(false);

  const { t } = useTranslation();

  const mappedItems = useMemo(() => {
    if (!lastVideos && fieldParagraph) {
      return mapPlaylistData(fieldParagraph);
    }
    return [];
  }, [fieldParagraph, lastVideos]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/api/video/${lastVideos ? `get-latests?locale=${locale}` : `get-playlist/${id}?locale=${locale}`}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();

        const mappedItemsFromAPI = result?.data?.map((item: any) => ({
          title: item.title,
          cta: {
            url: item.field_link?.uri || item.path.alias,
            target: item.field_link?.uri ? '_blank' : '_self',
            isExternal: !!item.field_link?.uri,
          },
          tags: item?.field_tags?.map((tag: any) => tag.name),
          imageAlt: item.field_media?.field_media_image?.resourceIdObjMeta?.alt || '',
          imageUrl: absoluteUrl(item.field_media?.field_media_image?.uri?.url) || '',
          date: item.field_date ? dayjs(item.field_date).format('DD MMMM YYYY') : '',
          variant: lastVideos ? 'last-items-card' : 'explore-videos-card',
        }));

        const data: CarouselModel = {
          title: fieldParagraph.field_title,
          items: mappedItemsFromAPI.slice(0, itemsToDisplay),
          cta: getFieldLink(fieldParagraph.field_cta),
          colorVariant: fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
          direction: fieldParagraph.behavior_settings?.style_options?.vertical_lines?.css_class || 'none',
          backgroundTransition: fieldParagraph.behavior_settings?.style_options?.transition?.css_class,
          anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
          itemsToDisplay: itemsToDisplay,
        };

        setData(result && data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (lastVideos) {
      fetchData();
    } else {
      const dataCarouselExplore: any = {
        title: name,
        items: mappedItems.slice(0, itemsToDisplay),
      };
      setData(dataCarouselExplore);
      setLoading(false);
    }
  }, [fieldParagraph, itemsToDisplay, lastVideos, mappedItems]);

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const { emblaApi, selectedIndex, scrollSnaps, emblaRef } = useCarousel({
    loop: lastVideos ? true : false,
    align: 'start',
  });

  const [ctaVariant, setCtaVariant] = useState<'secondary' | 'secondaryOnDark'>('secondary');
  useEffect(() => {
    const isHome = document?.body?.classList?.contains('page-home');
    if (isHome || data?.colorVariant === 'dark') {
      setCtaVariant('secondaryOnDark');
      return;
    }
    setCtaVariant('secondary');
  }, [data?.colorVariant]);

  const { textColor } = useColors('dark');
  const { backgroundColor } = useColors('dark');

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSeeAll = () => {
    onSeeAllClick(id, name);

    const element = document.getElementById('ListingVideoParagraph') as HTMLElement;

    if (element) {
      const yOffset = isMobile ? -72 : -144;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  useEffect(() => {
    if (!emblaApi) return;
    const checkButtons = () => {
      setShowPrevButton(emblaApi.canScrollPrev());
      setShowNextButton(selectedIndex < scrollSnaps.length - 6);
    };
    checkButtons();
    emblaApi.on('select', checkButtons);
    return () => {
      emblaApi.off('select', checkButtons);
    };
  }, [emblaApi, selectedIndex, scrollSnaps]);

  if (loading)
    return (
      <Box sx={{ backgroundColor: backgroundColor }}>
        <CircularProgressCustom height={lastVideos ? (isMobile ? 540 : 600) : isMobile ? 288 : 400} />
      </Box>
    );
  if (!data) return null;

  return (
    <>
      {/* {loading ? ( */}
      <Box
        bgcolor={backgroundColor}
        position={'relative'}
        sx={{ overflow: lastVideos && 'hidden' }}
        className={styles.carouselLastItemsParagraph}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FadeIn>
          <GridContainer
            className={lastVideos ? styles.lastVideosCont : styles.listVideoCont}
            sx={{
              paddingRight: '0 !important',
              paddingLeft: lastVideos ? 'inherit' : '0 !important',
            }}
          >
            <Row>
              <Grid item lg={!lastVideos ? 9 : 6} xs={!lastVideos ? 9 : 12}>
                <Typography
                  color={textColor}
                  textAlign={isMobile ? (!lastVideos ? 'left' : 'center') : 'left'}
                  mb={{ lg: theme.spacing(24), xs: lastVideos ? theme.spacing(40) : theme.spacing(8) }}
                  mt={{
                    xs: lastVideos ? theme.spacing(80) : theme.spacing(40),
                    lg: !lastVideos ? theme.spacing(40) : 0,
                  }}
                  className="font-weight-bolder"
                  variant={isMobile ? 'h6' : 'h3'}
                  component={'h3'}
                  fontWeight="500"
                >
                  {data?.title}
                </Typography>
              </Grid>
              {!lastVideos && (isMobile || isHovered) && (
                <Grid
                  item
                  xs={3}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  zIndex={101}
                  position={'relative'}
                >
                  <FadeIn>
                    <Button
                      sx={{
                        borderRadius: theme.spacing(8),
                        top: isMobile ? theme.spacing(8) : 0,
                        paddingBottom: isMobile ? 0 : `${theme.spacing(12)} ${theme.spacing(24)}`,
                        paddingRight: isMobile ? 0 : theme.spacing(16),
                        marginTop: !isMobile && theme.spacing(40),
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: theme.palette.alphaWhite['20'],
                        },
                      }}
                      onClick={handleSeeAll}
                      variant="small"
                      color="primary"
                      endIcon={<EastIcon />}
                    >
                      {isMobile ? '' : t('explorerVideo.seeAll')}
                    </Button>
                  </FadeIn>
                </Grid>
              )}
            </Row>

            <Row position={'relative'} zIndex={100}>
              <Grid item xs={12}>
                {data?.items?.length > 0 && (
                  <Box
                    ref={emblaRef}
                    overflow={{ xs: 'visible', lg: lastVideos && 'hidden' }}
                    pt={{ xs: 0, lg: 40 }}
                    mt={{ xs: 0, lg: -40 }}
                    mb={-40}
                    pb={40}
                  >
                    <Row flexWrap="nowrap">
                      {data?.items?.map((item, index) => (
                        <Grid
                          key={index}
                          position="relative"
                          item
                          xs={lastVideos ? 8 : 6}
                          lg={lastVideos ? 8 : 2}
                          paddingLeft={index != 0 && !isMobile ? `${theme.spacing(24)} !important` : ''}
                          flexShrink={0}
                          width={'100%'}
                          className={styles.itemCarousel}
                        >
                          <LinkChildren
                            isExternal={item?.cta?.isExternal}
                            url={isMobile ? '' : item?.cta?.url}
                            target={item?.cta?.target}
                          >
                            <CustomCard
                              index={index}
                              imageUrl={item.imageUrl}
                              createdAtDate={item.date}
                              tags={item.tags}
                              imageAlt={item.imageAlt}
                              label={item.title}
                              variant={item.variant}
                              path={item.path}
                              videoUrl={item.videoUrl}
                              abstract={item.abstract}
                            />
                          </LinkChildren>
                        </Grid>
                      ))}
                    </Row>
                  </Box>
                )}
                {!isMobile && data?.items?.length > 6 && (
                  <>
                    {lastVideos && (
                      <Box
                        sx={{
                          position: 'absolute',
                          left: lastVideos ? '90%' : '97%',
                          top: lastVideos ? '50%' : '40%',
                        }}
                      >
                        <RoundedIconButton
                          onClick={() => emblaApi.scrollNext()}
                          disabled={false}
                          colorVariant={'light'}
                          iconName="chevron_right"
                          style={{
                            backgroundColor: theme.palette.alphaWhite['80'],
                            border: '3px solid #fff',
                            color: theme.palette.common.black,
                          }}
                        />
                      </Box>
                    )}
                    {showPrevButton && !lastVideos && (
                      <Box sx={{ position: 'absolute', left: '1%', top: lastVideos ? '50%' : '40%' }}>
                        <RoundedIconButton
                          onClick={() => emblaApi.scrollPrev()}
                          colorVariant={'light'}
                          iconName="chevron_left"
                          style={{
                            backgroundColor: theme.palette.alphaWhite['80'],
                            border: '3px solid #fff',
                            color: theme.palette.common.black,
                          }}
                        />
                      </Box>
                    )}
                    {showNextButton && !lastVideos && (
                      <Box
                        sx={{
                          position: 'absolute',
                          left: lastVideos ? '90%' : '97%',
                          top: lastVideos ? '50%' : '40%',
                        }}
                      >
                        <RoundedIconButton
                          onClick={() => emblaApi.scrollNext()}
                          colorVariant={'light'}
                          iconName="chevron_right"
                          style={{
                            backgroundColor: theme.palette.alphaWhite['80'],
                            border: '3px solid #fff',
                            color: theme.palette.common.black,
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Grid>
            </Row>
          </GridContainer>
        </FadeIn>
      </Box>
      {/* ) : !lastVideos ? null : (
        <CircularProgressCustom />
      )} */}
    </>
  );
};

export default CarouselLastItemsParagraph;
