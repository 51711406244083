import Button from '@mui/material/Button';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PropsWithChildren, useMemo } from 'react';
import styles from './CircularProgressCustom.module.scss';
import { Box, CircularProgress } from '@mui/material';
import theme from '@mui/theme';

const CircularProgressCustom = (height) => {
  return (
    <Box minHeight={height ? height : '100%'} position={'relative'} width="100%">
      <CircularProgress className={styles.circularProgress} />
    </Box>
  );
};

export default CircularProgressCustom;
